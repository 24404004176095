<template>
    <div class="home-box">
      <!-- 查询区域 -->
  
      <!-- table区域-begin -->
      <div class="form-box">
        <el-form ref="form" :model="form" :rules="rules" label-width="140px" v-loading="loading">
          <div>
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">推广员佣金</span>
            </div>
            <el-divider/>
          </div>
          <el-form-item label="佣金" prop="promoter_cost">
              <el-input v-model="form.promoter_cost"  placeholder="请输入佣金">
                <template slot="append">%</template></el-input>
          </el-form-item>
          
          <div>
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">合伙人提现设置</span>
            </div>
            <el-divider/>
          </div>
          <el-form-item label="提现手续费" prop="agent_cost">
            <el-input v-model="form.agent_cost" placeholder="提现手续费 ">
              <template slot="append">%</template></el-input>
          </el-form-item>
          <el-form-item label="提现最小额度" prop="agent_balance">
              <el-input v-model="form.agent_balance" placeholder="提现最小额度"></el-input>
          </el-form-item>

          <div>
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">支付宝账号设置</span>
            </div>
            <el-divider/>
          </div>
          <el-form-item label="支付宝appid" prop="appid">
            <el-input v-model="form.appid" placeholder="支付宝appid "></el-input>
          </el-form-item>
          <el-form-item label="应用公钥" prop="cert_public_key">
              <el-input v-model="form.cert_public_key" placeholder="应用公钥 文件地址"></el-input>
          </el-form-item>
          <el-form-item label="支付宝公钥" prop="tixian_cert_public_key">
              <el-input v-model="form.tixian_cert_public_key" placeholder="支付宝公钥 文件地址"></el-input>
          </el-form-item>
          <el-form-item label="支付宝根证书" prop="root_key">
              <el-input v-model="form.root_key" placeholder="支付宝根证书 文件地址"></el-input>
          </el-form-item>
          <el-form-item label="支付宝应用秘钥" prop="pri_key">
              <el-input type="textarea" :rows="5" v-model="form.pri_key" placeholder="支付宝应用秘钥"></el-input>
          </el-form-item>
          <el-form-item label-width="50%">
              <el-button type="primary" @click="onSubmit('form')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- table区域-end -->
  
      <!-- 分页区域-begin -->
      <!-- 分页区域-end -->
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapState, mapMutations } from "vuex";
  import axios from "axios";
  
  export default({
    name: "agent",
    data() {
      return {
        form: {
          promoter_cost: '',
          agent_cost: '',
          agent_balance: '',

          appid: '',
          cert_public_key: '',
          tixian_cert_public_key: '',
          root_key: '',
          pri_key: '',
        },
        rules: {
          promoter_cost: [
            { required: true, message: '请输入佣金', trigger: 'blur' }
          ],
          agent_cost: [
            { required: true, message: '请输入提现手续费', trigger: 'blur' }
          ],
          agent_balance: [
            { required: true, message: '请输入提现最小额度', trigger: 'blur' }
          ],
        },
        loading: false,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
      // this.getautoshow()
      // this.getInfo()
      // this.getechart();
      this.getSetting()
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      getechart() {
        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById("chart"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: "核销券数",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            // prettier-ignore
            data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45'],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
            axisPointer: {
              snap: true,
            },
          },
          series: [
            {
              smooth: true,
              data: [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                12,
                33,
                222,
                333,
                123,
                233,
                112,
                212,
                444,
                333,
                1,
                11,
                22,
              ],
              type: "line",
            },
          ],
        });
      },
      getInfo() {
        axios
          .post("http://shunyu.jnwww.top/admin.php?action=setting&method=store", {
            is_get: 1,
            token: this.token,
          })
          .then((res) => {
            console.log(res);
            if (res.data.msg == "登录已过期") {
              localStorage.removeItem("user");
              this.$router.replace("/");
              return;
            }
            let data = {
              store: res.data.data.store_name,
              logo: res.data.data.store_logo,
            };
            this.setsetting(data);
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      getautoshow() {
        axios
          .get(
            "http://shunyu.jnwww.top/admin.php?action=autoshow&method=ex_list",
            {
              params: {
                token: this.token,
              },
            }
          )
          .then((res) => {
            if (res.data.msg == "登录已过期") {
              localStorage.removeItem("user");
              this.$router.replace("/");
              return;
            }
            res.data.data.data.forEach((item) => {
              this.options.push({
                label: item.exhibition_title,
                value: item.exhibition_id,
              });
              this.value = res.data.data.data[0].exhibition_id;
              this.getList();
            });
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      getList() {
        axios
          .post(
            "http://shunyu.jnwww.top/admin.php?action=autoshow&method=tk_list",
            {
              exhibition_ids: this.value,
              begin_time: this.date[0],
              end_time: this.date[1],
              page_idx: this.page,
              page_size: 10,
              token: this.token,
            }
          )
          .then((res) => {
            this.tableData = res.data.data.data;
            if (res.data.data.data.length == 0) {
              this.empty = this.page;
              this.$message.error("已无数据");
            } else {
              this.empty = 10000000000000000;
            }
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      // 提交表单
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            let formData = new FormData()
            formData.append('pri_key', this.form.pri_key)
            this.$api.cashOutSetting({...this.form, formData}).then(
              res => {
                if(res.code == 'success'){
                  this.$message.success(res.msg)
                  this.getSetting()
                }else{
                  this.$message.error(res.msg)
                }
                this.loading = false
              }
            ).catch(err=>{
              this.$message.error(err.msg)
              this.loading = false
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 获取已支付订单列表
      getSetting() {
        this.loading = true
        this.$api.getSetting({}).then(
          res => {
            if(res.code == 'success'){
              this.form = Object.assign({}, res.data);
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  </style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #949494;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 0 0 24px ;
}
</style>
  